<script>
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { mapGetters } from 'vuex';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import {
  createRecordV2,
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import AntInput from '@/components/AntInput.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';

export default {
  name: 'DhmeHallConfigurator',
  components: { AntInput, ModuleNavigationBar },
  data: () => {
    return {
      isLoading: false,

      halls: [],
      hallsTableId: null,
      hallListIndex: null,
      focusedHall: null,
      newHallTitle: '',
      newHallMenu: false,

      hallConfigurations: [],
      hallConfigurationsTableId: null,
      focusedConfiguration: null,
      newConfigurationTitle: '',
      newConfigurationMenu: false,

      configLanes: [],
      configLanesTableId: null,
      newLaneTitle: '',
      newLaneColor: '',
      newLaneMenu: false,
      laneIndex: null,
      focusedLane: null,
      updateLaneMenu: false,

      configSteps: [],
      configStepsTableId: null,
      newStepTitle: '',
      newStepMenu: false,
      focusedStep: null,
      updateStepMenu: false,

      configLocations: [],
      configLocationsTableId: null,
      focusedLocation: null,
      newLocationTitle: '',
      newLocationMenu: false,
      locationListIndex: null,

      locationTypes: [],
      locationTypesTableId: null,
      newTypeTitle: '',
      newTypeMenu: false,

      locationActivities: [],
      locationActivitiesTableId: null,
      newActivityTitle: '',
      newActivityMenu: false,
    };
  },
  computed: {
    ...mapGetters(['project', 'isDaiwaLicense']),
    locationsByLane() {
      return this.configLocations
        .filter((l) => l.assembly_lane === this.focusedLane.id)
        .sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    focusedHall(value) {
      if (this.$route.query.hall !== value.id) {
        this.$router.push({ query: { hall: value.id } });
      }
    },
    focusedConfiguration(value) {
      if (this.$route.query.configuration !== value.id) {
        this.$router.push({
          query: { hall: value.assembly_hall, configuration: value.id },
        });
      }
    },
    focusedLane(value) {
      if (this.$route.query.lane !== value.id) {
        this.$router.push({
          query: {
            hall: this.focusedHall.id,
            configuration: this.focusedConfiguration.id,
            lane: value.id,
          },
        });
      }
    },
    focusedLocation(value) {
      if (this.$route.query.location !== value.id) {
        this.$router.push({
          query: {
            hall: this.focusedHall.id,
            configuration: this.focusedConfiguration.id,
            lane: value.assembly_lane,
            location: value.id,
          },
        });
      }
    },
  },
  mounted() {
    this.fetchHalls();
  },
  methods: {
    getBlackOrWhiteBasedOnBackground,
    async fetchHalls() {
      this.isLoading = true;
      try {
        const { halls } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_HALLS',
              project: this.project.id,
              as: 'halls',
              columns: [
                {
                  name: 'hall',
                },
                {
                  name: 'type',
                },
                {
                  name: 'description',
                },
                {
                  name: 'automated',
                },
                {
                  name: 'manual_planning',
                },
              ],
            },
          ],
        });
        this.halls = halls.records;
        this.hallsTableId = halls.id;

        if (this.$route.query.hall) {
          this.focusOnHall(
            this.halls.find((h) => h.id === this.$route.query.hall)
          );
          this.hallListIndex = this.halls.findIndex(
            (h) => h.id === this.$route.query.hall
          );
        }
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnHall(hall) {
      this.focusedHall = { ...hall };
      this.focusedConfiguration = null;
      this.focusedLane = null;
      this.focusedLocation = null;
      this.fetchHallConfigurations();
    },
    async saveNewHall() {
      this.isLoading = true;
      try {
        const hall = await createRecordV2(this.hallsTableId, {
          hall: this.newHallTitle,
          automated: false,
          type: 'assembly',
          manual_planning: false,
        });
        this.halls.push(hall);
        this.newHallTitle = '';
        this.newHallMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async updateHall() {
      this.isLoading = true;
      try {
        const hall = await updateRecordV2(
          this.hallsTableId,
          this.focusedHall.id,
          this.focusedHall
        );
        Object.assign(
          this.halls.find((h) => h.id === this.focusedHall.id),
          hall
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async fetchHallConfigurations() {
      this.isLoading = true;
      try {
        const { configurations } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
              project: this.project.id,
              as: 'configurations',
              columns: [
                {
                  name: 'assembly_hall',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedHall.id,
                      boolean: 'or',
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'description',
                },
              ],
            },
          ],
        });
        this.hallConfigurations = configurations.records;
        this.hallConfigurationsTableId = configurations.id;

        if (this.$route.query.configuration) {
          this.focusOnConfiguration(
            this.hallConfigurations.find(
              (c) => c.id === this.$route.query.configuration
            )
          );
        }
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewConfiguration() {
      this.isLoading = true;
      try {
        const hall = await createRecordV2(this.hallConfigurationsTableId, {
          assembly_hall: this.focusedHall.id,
          title: this.newConfigurationTitle,
          description: `${this.newConfigurationTitle} Description`,
        });
        this.hallConfigurations.push(hall);
        this.newConfigurationTitle = '';
        this.newConfigurationMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    focusOnConfiguration(config) {
      this.focusedConfiguration = { ...config };
      this.fetchConfigurationLanesAndLocations();
      this.focusedLane = null;
      this.focusedLocation = null;
    },
    async updateConfiguration() {
      this.isLoading = true;
      try {
        const configuration = await updateRecordV2(
          this.hallConfigurationsTableId,
          this.focusedConfiguration.id,
          this.focusedConfiguration
        );
        Object.assign(
          this.hallConfigurations.find(
            (h) => h.id === this.focusedConfiguration.id
          ),
          configuration
        );
      } catch (e) {}
      this.isLoading = false;
    },

    async fetchConfigurationLanesAndLocations() {
      this.isLoading = true;
      try {
        const { lanes, steps, locations } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_LANES',
              project: this.project.id,
              as: 'lanes',
              sortBy: 'order',
              columns: [
                {
                  name: 'configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'color',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_STEPS',
              project: this.project.id,
              as: 'steps',
              sortBy: 'order',
              columns: [
                {
                  name: 'configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
              project: this.project.id,
              as: 'locations',
              sortBy: 'order',
              columns: [
                {
                  name: 'order',
                },
                {
                  name: 'hall_configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedConfiguration.id,
                    },
                  ],
                },
                {
                  name: 'assembly_location',
                },
                {
                  name: 'assembly_lane',
                },
                {
                  name: 'in_agv_route',
                },
                {
                  name: 'description',
                },
                {
                  name: 'manual_default',
                },
              ],
            },
          ],
        });
        this.configLanes = lanes.records;
        this.configLanesTableId = lanes.id;

        if (this.$route.query.lane) {
          this.focusOnLane(
            this.configLanes.find((c) => c.id === this.$route.query.lane)
          );
        }

        this.configSteps = steps.records;
        this.configStepsTableId = steps.id;

        this.configLocations = locations.records;
        this.configLocationsTableId = locations.id;

        if (this.$route.query.location) {
          this.focusOnLocation(
            this.configLocations.find(
              (c) => c.id === this.$route.query.location
            )
          );
          this.locationListIndex = this.configLocations.findIndex(
            (h) => h.id === this.$route.query.location
          );
        }
      } catch (e) {}

      this.isLoading = false;
    },
    async saveNewLane() {
      this.isLoading = true;
      try {
        const lane = await createRecordV2(this.configLanesTableId, {
          configuration: this.focusedConfiguration.id,
          title: this.newLaneTitle,
          color: this.newLaneColor,
          order: 0,
        });
        this.configLanes.push(lane);
        this.newLaneTitle = '';
        this.newLaneColor = '';
        this.newLaneMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    updateLaneColor(value) {
      console.log(value);
      this.focusedLane.color = value.hex;
    },
    async updateLane() {
      this.isLoading = true;
      try {
        const lane = await updateRecordV2(
          this.configLanesTableId,
          this.focusedLane.id,
          this.focusedLane
        );
        Object.assign(
          this.configLanes.find((h) => h.id === this.focusedLane.id),
          lane
        );
        this.updateLaneMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteLane() {
      this.isLoading = true;
      try {
        await deleteRecordV2(this.configLanesTableId, ...[this.focusedLane.id]);
        this.configLanes = this.configLanes.filter(
          (l) => l.id !== this.focusedLane.id
        );
        this.focusedLane = null;
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnLane(lane) {
      this.focusedLane = { ...lane };
      this.focusedLocation = null;
    },
    async saveNewStep() {
      this.isLoading = true;
      try {
        const lane = await createRecordV2(this.configStepsTableId, {
          configuration: this.focusedConfiguration.id,
          title: this.newStepTitle,
          order: 0,
        });
        this.configSteps.push(lane);
        this.newStepTitle = '';
        this.newStepMenu = false;
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async updateStep() {
      this.isLoading = true;
      try {
        const lane = await updateRecordV2(
          this.configStepsTableId,
          this.focusedStep.id,
          this.focusedStep
        );
        Object.assign(
          this.configSteps.find((h) => h.id === this.focusedStep.id),
          lane
        );
        this.updateStepMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteStep() {
      this.isLoading = true;
      try {
        await deleteRecordV2(this.configStepsTableId, ...[this.focusedStep.id]);
        this.configSteps = this.configSteps.filter(
          (l) => l.id !== this.focusedStep.id
        );
        this.focusedStep = null;
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewLocation() {
      this.isLoading = true;
      try {
        const lane = await createRecordV2(this.configLocationsTableId, {
          assembly_hall: this.focusedHall.id,
          hall_configuration: this.focusedConfiguration.id,
          assembly_lane: this.focusedLane.id,
          assembly_location: this.newLocationTitle,
          order: 0,
        });
        this.configLocations.push(lane);
        this.newLocationTitle = '';
        this.newLocationMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    updateLocationBtn(column) {
      this.focusedLocation[column] = !this.focusedLocation[column];
      this.updateLocation();
    },
    async updateLocation() {
      this.isLoading = true;
      try {
        const location = await updateRecordV2(
          this.configLocationsTableId,
          this.focusedLocation.id,
          this.focusedLocation
        );
        Object.assign(
          this.configLocations.find((h) => h.id === this.focusedLocation.id),
          location
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteLocation() {
      this.isLoading = true;
      try {
        await deleteRecordV2(
          this.configLocationsTableId,
          ...[this.focusedLocation.id]
        );
        this.configLocations = this.configLocations.filter(
          (l) => l.id !== this.focusedLocation.id
        );
        this.focusedLocation = null;
      } catch (e) {}
      this.isLoading = false;
    },
    focusOnLocation(location) {
      this.focusedLocation = { ...location };

      this.fetchLocationTypesAndActivities();
    },
    async fetchLocationTypesAndActivities() {
      this.isLoading = true;
      try {
        const { types, activities } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATION_TYPES',
              project: this.project.id,
              as: 'types',
              sortBy: 'order',
              columns: [
                {
                  name: 'assembly_location',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedLocation.id,
                    },
                  ],
                },
                {
                  name: 'step',
                },
                {
                  name: 'type',
                },
                {
                  name: 'friendly_name',
                },
                {
                  name: 'order',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATION_ACTIVITIES',
              project: this.project.id,
              as: 'activities',
              sortBy: 'order',
              columns: [
                {
                  name: 'assembly_location',
                  conditions: [
                    {
                      operator: '=',
                      value: this.focusedLocation.id,
                    },
                  ],
                },
                {
                  name: 'step',
                },
                {
                  name: 'activity',
                },
                {
                  name: 'order',
                },
                {
                  name: 'leanforms_q_nr',
                },
                {
                  name: 'leanforms_remaining_point',
                },
                {
                  name: 'btn_options',
                },
              ],
            },
          ],
        });

        this.locationTypes = types.records;
        this.locationTypesTableId = types.id;

        this.locationActivities = activities.records;
        this.locationActivitiesTableId = activities.id;
      } catch (e) {}
      this.isLoading = false;
    },

    async saveNewType() {
      this.isLoading = true;
      try {
        const type = await createRecordV2(this.locationTypesTableId, {
          assembly_location: this.focusedLocation.id,
          type: this.newTypeTitle,
          friendly_name: this.newTypeTitle,
          step: '1',
          order: 0,
        });
        this.locationTypes.push(type);
        this.newTypeTitle = '';
        this.newTypeMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },
    async saveNewActivity() {
      this.isLoading = true;
      try {
        const activity = await createRecordV2(this.locationActivitiesTableId, {
          assembly_location: this.focusedLocation.id,
          activity: this.newActivityTitle,
          step: '1',
          order: 0,
        });
        this.locationActivities.push(activity);
        this.newActivityTitle = '';
        this.newActivityMenu = false;
      } catch (e) {}
      this.isLoading = false;
    },

    async updateType(item) {
      this.isLoading = true;
      try {
        const type = await updateRecordV2(
          this.locationTypesTableId,
          item.id,
          item
        );
        Object.assign(
          this.locationTypes.find((h) => h.id === this.focusedLocation.id),
          type
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteType(item) {
      this.isLoading = true;
      try {
        await deleteRecordV2(this.locationTypesTableId, item.id);
        this.locationTypes = this.locationTypes.filter((t) => t.id !== item.id);
      } catch (e) {}
      this.isLoading = false;
    },

    async updateActivity(item) {
      this.isLoading = true;
      try {
        const activity = await updateRecordV2(
          this.locationActivitiesTableId,
          item.id,
          item
        );
        Object.assign(
          this.locationActivities.find((h) => h.id === this.focusedLocation.id),
          activity
        );
      } catch (e) {}
      this.isLoading = false;
    },
    async deleteActivity(item) {
      this.isLoading = true;
      try {
        await deleteRecordV2(this.locationActivitiesTableId, item.id);
        this.locationActivities = this.locationActivities.filter(
          (t) => t.id !== item.id
        );
      } catch (e) {}
      this.isLoading = false;
    },
    updateColor(value) {
      this.newLaneColor = value.hex;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Hall configurator">
      <template #module-nav-logo>
        <img
            v-if="isDaiwaLicense"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-scroll-height">
      <div
        class="background-white ant-border-right overflow-y-auto"
        style="width: 400px"
      >
        <v-list subheader two-line>
          <v-subheader
            >Halls
            <v-spacer />

            <v-menu v-model="newHallMenu" right :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <div class="background-white pa-1">
                <v-text-field
                  v-model="newHallTitle"
                  placeholder="Title"
                  filled
                  hide-details
                  autofocus
                  single-line
                  @keydown.enter="saveNewHall"
                />
              </div>
            </v-menu>
          </v-subheader>
          <v-list-item-group v-model="hallListIndex" color="primary">
            <v-list-item
              v-for="hall in halls"
              :key="hall.id"
              @click="focusOnHall(hall)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ hall.hall }}</v-list-item-title>

                <v-list-item-subtitle
                  >{{ hall.description }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-chip>
                  {{ hall.type }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="flex-grow-1 d-flex flex-column" style="width: 0">
        <v-progress-linear
          v-show="isLoading"
          indeterminate
          color="primary"
          height="5"
        />
        <div v-if="focusedHall" class="d-flex flex-column flex-grow-1">
          <div class="d-flex px-5 py-2 background-white ant-border-bottom">
            <ant-input label="type" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-select
                  v-model="focusedHall.type"
                  :items="['assembly', 'production']"
                  filled
                  single-line
                  dense
                  hide-details
                  placeholder="type"
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input label="title" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.hall"
                  filled
                  single-line
                  dense
                  hide-details
                  placeholder="title"
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input label="description" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-text-field
                  v-model="focusedHall.description"
                  filled
                  single-line
                  dense
                  hide-details
                  placeholder="description"
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <v-spacer />
            <ant-input label="automated" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-checkbox
                  v-model="focusedHall.automated"
                  class="mt-1"
                  hide-details
                  @change="updateHall"
                />
              </template>
            </ant-input>
            <ant-input label="manual planning" top-margin="mt-0">
              <template #input-field>
                <v-checkbox
                  v-model="focusedHall.manual_planning"
                  class="mt-1"
                  hide-details
                  @change="updateHall"
                />
              </template>
            </ant-input>
          </div>
          <div class="d-flex align-center px-10 pt-2 pb-5 ant-border-bottom">
            <v-subheader class="px-0 mt-5">Configurations</v-subheader>
            <v-menu
              v-model="newConfigurationMenu"
              right
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" class="mx-2 mt-5" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <div class="background-white pa-1">
                <v-text-field
                  v-model="newConfigurationTitle"
                  placeholder="Title"
                  filled
                  hide-details
                  autofocus
                  single-line
                  @keydown.enter="saveNewConfiguration"
                />
              </div>
            </v-menu>
            <ant-input label="Configuration" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-combobox
                  v-model="focusedConfiguration"
                  :items="hallConfigurations"
                  item-text="title"
                  filled
                  hide-details
                  dense
                  placeholder="configuration"
                  return-object
                  class="mr-2"
                  @change="focusOnConfiguration"
                />
              </template>
            </ant-input>
            <v-spacer />

            <ant-input label="Title" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-text-field
                  v-if="focusedConfiguration"
                  v-model="focusedConfiguration.title"
                  filled
                  hide-details
                  dense
                  placeholder="title"
                  @change="updateConfiguration"
                />
              </template>
            </ant-input>

            <ant-input label="Description" top-margin="mt-0" class="mr-2">
              <template #input-field>
                <v-text-field
                  v-if="focusedConfiguration"
                  v-model="focusedConfiguration.description"
                  filled
                  hide-details
                  dense
                  placeholder="description"
                  @change="updateConfiguration"
                />
              </template>
            </ant-input>
          </div>
          <div
            v-if="focusedConfiguration"
            class="flex-grow-1 d-flex flex-column"
          >
            <div class="d-flex align-center ant-border-bottom px-5">
              <v-subheader>Lanes</v-subheader>
              <v-menu
                v-model="newLaneMenu"
                right
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <div class="background-white pa-1">
                  <v-text-field
                    v-model="newLaneTitle"
                    placeholder="Title"
                    filled
                    hide-details
                    autofocus
                    single-line
                    @keydown.enter="saveNewLane"
                  />
                  <v-color-picker
                    v-model="newLaneColor"
                    hide-canvas
                    hide-inputs
                    @update:color="updateColor"
                  />
                </div>
              </v-menu>
              <div class="flex-grow-1 overflow-x-auto">
                <v-tabs
                  v-model="laneIndex"
                  tile
                  color="primary"
                  group
                  background-color="transparent"
                >
                  <v-tab
                    v-for="lane in configLanes"
                    :key="lane.id"
                    text
                    center-active
                    style="width: calc(100% - 70px - 52px)"
                    class="ma-0"
                    @click="focusOnLane(lane)"
                  >
                    <v-chip
                      :color="lane.color ?? $vuetify.theme.themes.light.primary"
                      :text-color="
                        getBlackOrWhiteBasedOnBackground(
                          lane.color ?? $vuetify.theme.themes.light.primary
                        )
                      "
                      >{{ lane.title }}
                      <span class="ml-2 fs-10">({{ lane.order }})</span></v-chip
                    >
                  </v-tab>
                </v-tabs>
              </div>

              <v-menu
                v-if="focusedLane"
                v-model="updateLaneMenu"
                left
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <div class="background-white px-5 py-2">
                  <ant-input label="Title">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLane.title"
                        placeholder="Title"
                        filled
                        hide-details
                        autofocus
                        single-line
                        dense
                      />
                    </template>
                  </ant-input>

                  <ant-input label="Order">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLane.order"
                        placeholder="Order"
                        filled
                        type="number"
                        hide-details
                        autofocus
                        single-line
                        dense
                      />
                    </template>
                  </ant-input>
                  <v-color-picker
                    v-model="focusedLane.color"
                    hide-canvas
                    hide-inputs
                    @update:color="updateLaneColor"
                  />
                  <div class="d-flex">
                    <v-spacer />
                    <v-btn small color="primary" @click="updateLane"
                      >Save</v-btn
                    >
                  </div>
                </div>
              </v-menu>
              <v-btn v-if="focusedLane" icon @click="deleteLane">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div class="d-flex align-center ant-border-bottom px-5">
              <v-subheader>Steps</v-subheader>
              <v-menu
                v-model="newStepMenu"
                right
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <div class="background-white pa-1">
                  <v-text-field
                    v-model="newStepTitle"
                    placeholder="Title"
                    filled
                    hide-details
                    autofocus
                    single-line
                    @keydown.enter="saveNewStep"
                  />
                </div>
              </v-menu>

              <div v-if="focusedStep" class="mr-2">{{ focusedStep.order }}</div>

              <v-select
                v-model="focusedStep"
                :items="configSteps"
                filled
                dense
                hide-details
                item-text="title"
                item-value="id"
                return-object
                style="max-width: 250px"
              >
                <template #item="{ item }">
                  {{ item.order }} - {{ item.title }}
                </template>
              </v-select>
              <v-spacer />
              <v-menu
                v-if="focusedStep"
                v-model="updateStepMenu"
                left
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <div class="background-white px-5 py-2">
                  <ant-input label="Title">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedStep.title"
                        placeholder="Title"
                        filled
                        hide-details
                        dense
                        autofocus
                        single-line
                      />
                    </template>
                  </ant-input>

                  <ant-input label="Order">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedStep.order"
                        placeholder="Order"
                        filled
                        type="number"
                        hide-details
                        dense
                        autofocus
                        single-line
                      />
                    </template>
                  </ant-input>
                  <div class="d-flex mt-2">
                    <v-spacer />
                    <v-btn small color="primary" @click="updateStep"
                      >Save</v-btn
                    >
                  </div>
                </div>
              </v-menu>
              <v-btn v-if="focusedStep" icon @click="deleteStep">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>

            <div
              v-if="focusedLane"
              class="flex-grow-1 d-flex"
              style="height: 0"
            >
              <div
                class="background-white ant-border-right overflow-y-auto"
                style="width: 300px"
              >
                <v-list two-line subheader>
                  <v-subheader
                    >Locations
                    <v-spacer />
                    <v-menu
                      v-model="newLocationMenu"
                      right
                      :close-on-content-click="false"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>

                      <div class="background-white pa-1">
                        <v-text-field
                          v-model="newLocationTitle"
                          placeholder="Title"
                          filled
                          hide-details
                          autofocus
                          single-line
                          @keydown.enter="saveNewLocation"
                        />
                      </div>
                    </v-menu>
                  </v-subheader>
                  <v-list-item-group
                    v-model="locationListIndex"
                    color="primary"
                  >
                    <v-list-item
                      v-for="location in locationsByLane"
                      :key="location.id"
                      @click="focusOnLocation(location)"
                    >
                      <span class="fs-20 mr-4">{{ location.order }}</span>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          location.assembly_location
                        }}</v-list-item-title>

                        <v-list-item-subtitle
                          >{{ location.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <div class="d-flex">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="updateLocationBtn('manual_default')"
                              >
                                <v-icon>{{
                                  location.manual_default
                                    ? 'mdi-star-four-points-circle'
                                    : 'mdi-star-four-points-circle-outline'
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span>Manual default</span>
                          </v-tooltip>

                          <v-tooltip v-if="focusedHall.automated" bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="updateLocationBtn('in_agv_route')"
                              >
                                <v-icon>{{
                                  location.in_agv_route
                                    ? 'mdi-network'
                                    : 'mdi-network-outline'
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span>AGV location</span>
                          </v-tooltip>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <div v-if="focusedLocation" class="overflow-y-auto flex-grow-1">
                <v-subheader>General</v-subheader>
                <div class="d-flex px-5 justify-start">
                  <ant-input label="Location Nr" class="mr-4" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.order"
                        type="number"
                        single-line
                        label="number"
                        hide-details
                        dense
                        filled
                        :min="0"
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <ant-input label="Title" class="mr-4" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.assembly_location"
                        type="text"
                        single-line
                        hide-details
                        label="title"
                        dense
                        filled
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <ant-input label="Description" top-margin="">
                    <template #input-field>
                      <v-text-field
                        v-model="focusedLocation.description"
                        type="text"
                        single-line
                        hide-details
                        label="description"
                        dense
                        filled
                        @change="updateLocation"
                      />
                    </template>
                  </ant-input>
                  <v-spacer />
                  <v-btn icon class="mt-5" @click="deleteLocation">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-subheader
                  >Types
                  <v-spacer />
                  <v-menu
                    v-model="newTypeMenu"
                    right
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-text-field
                        v-model="newTypeTitle"
                        placeholder="Title"
                        filled
                        hide-details
                        autofocus
                        single-line
                        @keydown.enter="saveNewType"
                      />
                    </div>
                  </v-menu>
                </v-subheader>
                <v-data-table
                  :items="locationTypes"
                  :headers="[
                    { text: 'Element Type', value: 'type' },
                    { text: 'Friendly Name', value: 'friendly_name' },
                    { text: 'Step', value: 'step' },
                    { text: 'Order', value: 'order' },
                    { text: '', value: 'actions' },
                  ]"
                >
                  <template #item.type="{ value, item }">
                    <v-text-field
                      v-model="item.type"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.friendly_name="{ value, item }">
                    <v-text-field
                      v-model="item.friendly_name"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.step="{ value, item }">
                    <v-select
                      v-model="item.step"
                      :items="configSteps"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      item-value="id"
                      item-text="title"
                      filled
                      type="number"
                      @change="updateType(item)"
                    >
                      <template #item="{ item }">
                        {{ item.order }} - {{ item.title }}
                      </template>
                    </v-select>
                  </template>
                  <template #item.order="{ value, item }">
                    <v-text-field
                      v-model="item.order"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      type="number"
                      @change="updateType(item)"
                    />
                  </template>
                  <template #item.actions="{ item }">
                    <v-btn icon @click="deleteType(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
                <v-subheader
                  >Activities
                  <v-spacer />
                  <v-menu
                    v-model="newActivityMenu"
                    right
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" class="mx-2" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-text-field
                        v-model="newActivityTitle"
                        placeholder="Title"
                        filled
                        hide-details
                        autofocus
                        single-line
                        @keydown.enter="saveNewActivity"
                      />
                    </div>
                  </v-menu>
                </v-subheader>
                <v-data-table
                  :items="locationActivities"
                  :headers="[
                    { text: 'Activity', value: 'activity' },
                    { text: 'Step', value: 'step' },
                    { text: 'Order', value: 'order' },
                    { text: 'LF Q Nr', value: 'leanforms_q_nr' },
                    {
                      text: 'LF Remaining point',
                      value: 'leanforms_remaining_point',
                    },
                    { text: 'Options', value: 'btn_options' },
                    { text: '', value: 'actions' },
                  ]"
                >
                  <template #item.activity="{ value, item }">
                    <v-text-field
                      v-model="item.activity"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.step="{ value, item }">
                    <v-select
                      v-model="item.step"
                      :items="configSteps"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      item-text="title"
                      item-value="id"
                      type="number"
                      @change="updateActivity(item)"
                    >
                      <template #item="{ item }">
                        {{ item.order }} - {{ item.title }}
                      </template>
                    </v-select>
                  </template>
                  <template #item.order="{ value, item }">
                    <v-text-field
                      v-model="item.order"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      type="number"
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.leanforms_q_nr="{ value, item }">
                    <v-text-field
                      v-model="item.leanforms_q_nr"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.leanforms_remaining_point="{ value, item }">
                    <v-text-field
                      v-model="item.leanforms_remaining_point"
                      dense
                      single-line
                      hide-details
                      :value="value"
                      filled
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.btn_options="{ value, item }">
                    <v-select
                      v-model="item.btn_options"
                      dense
                      :items="[
                        { text: 'Yes', value: '1' },
                        { text: 'Yes & No', value: '2' },
                        { text: 'Yes & Cancel', value: '3' },
                        { text: 'Yes & No & Cancel', value: '4' },
                      ]"
                      single-line
                      hide-details
                      :value="value"
                      filled
                      item-text="text"
                      item-value="value"
                      @change="updateActivity(item)"
                    />
                  </template>
                  <template #item.actions="{ item }">
                    <v-btn icon @click="deleteActivity(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
